require('./bootstrap');
import Vue from 'vue';
import Collapse from './Collapse.vue';
import VModal from 'vue-js-modal';

Vue.use(VModal, { dialog: true });

new Vue({
    el: '#app',
    components: {
        Collapse,
    },
    data() {
        return {
            reviewForm: {
                firstname: '',
                lastname: '',
                quote: '',
                anonymous: false
            },
            contactForm: {
                firstname: '',
                lastname: '',
                mail: '',
                message: ''
            },
            background: document.querySelector(".dark-background"),
            isMenuHidden: false,
            imageViewOpen: false,
            Muurschildering: {
                active: false
            },
            Schilderij: {
                active: false
            },
        }
    },
    mounted() {
        this.background = document.querySelector(".dark-background");

        if(window.location.hash && document.querySelector(window.location.hash)) {
            zenscroll.to(document.querySelector(window.location.hash))
        }
    },
    methods: {
        sendMail(e) {
            // TODO: maybe do something...
            e.preventDefault()

            return false;
        },
        submitReview(e) {
            e.preventDefault();

            axios.post(e.target.action, this.reviewForm).then((response) => {
                this.$modal.show('dialog', {
                    title: 'Succes',
                    text: response.data.message,
                    buttons: [
                        {
                            title: 'OK',
                            handler: () => {
                                this.$modal.hide('dialog')

                                window.location.replace('/#reviewsAnchor')
                                window.location.reload()
                            }
                        }
                    ]
                })

            }).catch((errors) => {
                this.$modal.show('dialog', {
                    title: 'Error',
                    text: errors,
                    buttons: [
                        {
                            title: 'OK',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        }
                    ]
                })
            });

            return false;
        },
        moveContact(offset) {
            offset *= -1;
            var info = document.getElementById("switchInfo");
            var form = document.getElementById("switchMailForm");
            var reviewform = document.getElementById("switchReviewForm");
            info.style.transform = "translateX("+offset+"%)";
            form.style.transform = "translateX("+(offset+100)+"%)";
            reviewform.style.transform = "translateX("+(offset-100)+"%)";
        },
        gallery(url, srcset) {
            if(!this.imageViewOpen) {
                this.openImgView(url, srcset);
            } else {
                this.closeImgView();
            }
        },
        openImgView(url, srcset) {
            const view = document.querySelector('.photo-gallery-img-view');

            if(!view) {
                return
            }
            const img = view.querySelector("img");
            img.src = url;
            img.srcset = srcset;
            img.onload = () => {
                window.requestAnimationFrame(function(){if(!(size=img.getBoundingClientRect().width))return;img.onload=null;img.sizes=Math.ceil(size/window.innerWidth*100)+'vw';})
            };
            view.style.zIndex = "5";

            view.style.opacity = "1";

            this.imageViewOpen = true;
        },
        closeImgView() {
            const view = document.querySelector('.photo-gallery-img-view');
            if(!view) {
                return
            }
            view.style.opacity = "0";
            setTimeout(() => {
                view.style.zIndex = "-1";
                view.querySelector("img").src = "";
            }, 600)

            this.imageViewOpen = false;
        },
        toggle(name) {
            this[name].active = !this[name].active;
        },
        openMenu() {
            var menu = document.getElementById("pushMenu");
            menu.classList.add("menuOpen");
            this.background.style.zIndex = "1"
            this.background.style.background = "rgba(0, 0, 0, 0.5)";
            document.querySelector(".logo").style.transformX = "-25%";
            document.querySelector(".logo").style.left = "25%";
            document.getElementById("closeMenu").style.display = "block";
            document.getElementById("menu").style.display = "none";

            var list = document.getElementsByTagName("ul");
            var listItems = document.getElementsByTagName("li");
            list[0].classList.add("menuOpenUl");
            for (var i = 0; i < listItems.length; i++) {
              listItems[i].classList.add("menuOpenLi");
            }
            this.isMenuHidden = false;
        },
        closeMenu() {
            var menu = document.getElementById("pushMenu");
            menu.classList.remove("menuOpen");
            this.background.style.background = "rgba(0, 0, 0, 0)";
            setTimeout(() => {
                this.background.style.zIndex = "-1";
            }, 600)
            document.querySelector(".logo").style.transformX = "-50%";
            document.querySelector(".logo").style.left = "50%";
            document.getElementById("closeMenu").style.display = "none";
            document.getElementById("menu").style.display = "block";
            var listItems = document.getElementsByTagName("li");
            var list = document.getElementsByTagName("ul");
            list[0].classList.remove("menuOpenUl");
            for (var i = 0; i < listItems.length; i++) {
                listItems[i].classList.remove("menuOpenLi");
            }
            this.isMenuHidden = true;
        }
    }
});
