<template>
<div ref="collapse" class="collapse" :class="active ? 'expanded' : ''" :style="active ? 'max-height: ' + height : ''">
    <slot></slot>
</div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean
        }
    },
    data() {
        return {
            height: '1000px',
        }
    },
    mounted() {
        // setTimeout(() => {
            this.$refs.collapse.style.maxHeight = 'initial';
            this.height = this.$refs.collapse.clientHeight + 'px';
            this.$refs.collapse.style.maxHeight = '0px';
        // }, 500)
    },
    watch: {
        active: function(newVal, oldVal) {
            if(newVal) {
                setTimeout(() => {
                    this.$refs.collapse.style.maxHeight = 'initial'; // make sure you see everyting, without animation... but works
                }, 400);
            }
        }
    }
}
</script>

<style scoped>
.collapse {
    max-height: 0;
    overflow: hidden;
    transition: 300ms max-height ease-in-out;
}
.collapse::after {
  content: "";
  clear: both;
  display: table;
}
.expanded {
    max-height: 1000px;
}
</style>
